<template>
  <div class="animated fadeIn container">
    <div class="card p-4" style="width:100%;margin:0 auto;max-width: 600px">
      <h2>런던문자 충전</h2>
      <p class="desc">
        - 충전은 입금하시면 보통 2분, 늦어도 10분 안에는 자동으로 처리됩니다.
        <img src='/img/i_smile.png' style="width:15px;transform:translateY(-2px)" />
      </p>
      <p class="desc">- 입금자명은 바뀌어도 무관하며 금액만 맞으면 처리됩니다.</p>
      <p class="text-center color-skyblue my-4" style="font-size:30px">{{ payment.amount | numberFormat }}원</p>
      <p class="text-color color-skyblue my-2">
        {{ payment.vbankName }} / {{ payment.vbankNum }} (예금주:{{ payment.vbankHolder }}) <br />입금기한:
        {{ parsedDate(payment.vbankDate) }}
      </p>

      <b-btn variant="primary" class="mt-4" @click.prevent="clickConfirm">확인</b-btn>
    </div>
  </div>
</template>
<script>
import PaymentService from '@/services/PaymentService'
import moment from 'moment-timezone'

export default {
  data() {
    return {
      payment: {},
    }
  },
  mounted() {
    this.getPayment()
  },
  methods: {
    async getPayment() {
      const response = await PaymentService.get(this.$route.params.id)
      console.log(response)
      if (response.status == 200) {
        this.payment = response.data.payment
      }
    },
    parsedDate(value) {
      return moment.unix(value).format('YYYY. MM. DD LTS')
    },
    clickConfirm() {
      this.$router.push('/payment/history')
    },
  },
}
</script>

<style scoped lang="scss">
.button-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.button-container>div {
  display: flex;
  align-items: center;
}

.button-list {
  list-style: none;
  width: 100%;
  line-height: 20px;
}
.button-list>li {
  float: left;
  color: white;
  position: relative;
  width: 23%;
  margin-left: 2%;
  padding: 4px 0;
  margin: 3px 1%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: -0.5px;
  height: 28px;
  overflow: auto;
  min-width: 70px;
}

.deposit-btn {
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
}

.desc {
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: #999;
  font-size: 16px;

  &:first-of-type {
    margin-top: 1rem;
  }
}
</style>
